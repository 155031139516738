@font-face{
font-family: Rubric-Regular;
src: url("/src/fonts/Rubik-Regular.ttf");
}
.new-message {
  display: none !important;
}
.you,
.you::before {
  background: #126bc9 !important;
  font-family: Rubric-Regular;
}
.me,
.me::before {
  background: #797c7f !important;
  font-family: Rubric-Regular;
}
.home_unit_num_wrapper{
  position: relative;
}
.home_unit_num_input{
  display: flex;
  width: 100%; 
}
.home_send_icon{
  width: 30px;
  position: absolute;
  top: 8px;
  right: 25px;
}
.home_wrapper{
  margin-top: 125px;
  margin-bottom: 50vh;
}
