.Spinner {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(230, 233, 237, .5);
    z-index: 1000;
}

.SpinnerIcon {
    border: 16px solid #e6e9ed;
    border-top: 16px solid #1787fe;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}