.navLogo {
  width: 250px;
}

.titleBar {
  background-color: white;
  box-shadow: 0 0.625rem 1.25rem 0 rgb(0 0 0 / 10%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
