@font-face{
  font-family: Rubric-Regular;
  src: url("./fonts/Rubik-Regular.ttf");
}
body, h1{
  font-family: Rubric-Regular;
  color: #53565a;
}

.button {
  font-size: 18px;
  width: 194px;
  height: 40px;
  padding: 9px 13px 9px 14px;
  border-radius: 50px;
  letter-spacing: -0.64px;
  text-align: center;
  line-height: 9px;
  margin-left: 50px;
}
.button_primary {
  border-color: transparent;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(98deg, #1787fe, #126bc9 93%);
  color: white;
}
.button_secondary {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #1787fe;
  background-color: white;
  color: #1787fe;
}
.button_section {
  display: flex;
  width: 100%;
  justify-content: center;
}
.button_section .submit-invalid{
  cursor: not-allowed;
  background-image: linear-gradient(98deg, #f4f6f9, #bdc3ca 96%);
  color: #53565a;
}
.input {
  border-radius: 30.5px;
  border: solid 1px #d8dbdf;
  padding: 10px 0px 10px 15px;
}
.cancel_btn{
  color: #126bc9;
  padding-left: 15px;
  font-size: 10pt;
   background: none;
   border: none;
}
.cta_section {
  margin-top: 75px;
}
.select {
  width: 500px;
  height: 49px;
  margin: 0 13.1px 6px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.3px;
  color: #1787fe;
  border-color: transparent;
  border-bottom: 3px solid #1787fe;
  appearance: none;
  padding-left: 25px;
}
.custom_arrow {
  position: absolute;
  width: 25px;
  right: 50px;
}
.custom_arrow.down{
  top: 10px;
  transform: rotate(180deg);
}
.custom_arrow.up{
  top: 25px;
}
.options_wrapper {
  padding: 0;
  border-radius: 0 0 30px 30px;
  width: 450px;
}
.options {
  color: #797c7f;
  list-style: none;
  font-size: 24px;
  width: 500px;
  z-index: 200;
  width: 500px;
  margin: 0 auto;
}
.options:nth-child(odd) {
  background: #f4f6f7;
 
}
.options:hover {
  background: #126bc9;
  color: white;
}
.dropdown_label,
.dropdown_label .active {
  border-bottom: solid 3px #1787fe;
  padding-left: 15px;
  font-size: 24px;
  color: #1787fe;
  width: 500px;
  position: relative;
}
.active {
  padding: 16px 33px 16px 21px;
  border-radius: 30px 30px 0 0;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(97deg, #1787fe, #126bc9 93%);
  color: white;
  border-bottom: none;
}
.dropdown_wrapper {
  width: 500px;
  margin: 24px auto;
  border-radius: 20px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
