.edit_dialog_input {
  width: 1000px;  
}
.edit_input_sections_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 20px;
  margin-top: 25px;
}
.edit_input_section {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 8fr;
  
}
